import React, { useState } from "react";
import { toast } from 'react-toastify';
import { useConsulta } from '@hooks/useConsulta';
import { Loading } from '../../components/Loading';

import ImgColarDisable from '@assets/new-header/colar_disabled.svg';
import ImgColar from '@assets/new-header/colar_enabled.svg';

import { Container, Image, DefaultButton } from './styles';

export function BotaoColar({ disabled, loading, changeLoading }) {
  const { enviarDoClipboard } = useConsulta();

  function getClipboard() {
    var t = document.createElement("input");
    document.body.appendChild(t);
    t.focus();
    document.execCommand("paste");
    var clipboardText = t.value; //this is your clipboard data
    document.body.removeChild(t);
    return clipboardText;
  }

  async function handleColar() {
    changeLoading(true);
    try {
      await enviarDoClipboard(getClipboard());
    } catch(error) {
      toast.error(error.message);
    } finally {
      changeLoading(false);
    }
  }

  if(disabled) {
    return (<Image src={ImgColarDisable} alt="Colar" disabled={true}/>);
  }

  if(loading) {
    return (<DefaultButton><Loading /></DefaultButton>);
  }

  //<Image src={ImgColar} alt="Colar" />
  return (<Container onClick={handleColar} className="ui_labels_bold">
    <Image src={ImgColar} alt="Colar" />
  </Container>);
}